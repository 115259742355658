import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Bell,
  CircleUser,
  Home,
  LineChart,
  Menu,
  Package,
  Package2,
  Search,
  ShoppingCart,
  Users,
} from "lucide-react";

import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Input } from "../components/ui/input";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import zIndex from "@mui/material/styles/zIndex";
import { motion } from "framer-motion";

const Sidebar = ({ theme, ...props }) => {
  return (
    <div className="flex h-full max-h-screen flex-col gap-2">
       {/*
       <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <Link to="/" className="flex items-center gap-2 font-semibold">

        <motion.image
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            style={{ backgroundColor: "transparent" }}
          >

          <img
            src={
              theme === "dark"
                ? "https://cloata.com/wp-content/uploads/2024/07/cloata-logo-dark.png"
                : "https://cloata.com/wp-content/uploads/2024/07/cloata-logo-white-bg.jpg"
            }
            width={110}
            alt="Cloata Logo"
          />

          </motion.image>
        </Link>

        <Button variant="outline" size="icon" className="ml-auto h-8 w-8">

          <span className="sr-only">Toggle notifications</span>
        </Button>
         
      </div>
*/}
      <div className="flex-1">
        <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
          {/* 

          <Link
            to="/dashboard"
            className="flex items-center gap-3 rounded-lg px-3 py-2 text-primary text-muted-foreground bg-muted transition-all hover:text-primary"
          >
            <Home className="h-4 w-4" />
            Dashboard
          </Link>
          <Link
            to="/contacts"
            className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
          >
            <ShoppingCart className="h-4 w-4" />
            Contacts
            <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
              6
            </Badge>
          </Link>

           */}

          <div {...props}>{/* other content */}</div>
          <Link
            className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toast.info("PREMIUM FEATURE!");
            }}
          >
            <ShoppingCart className="h-4 w-4" />
            HOT Leads
          </Link>
        </nav>
      </div>



      {/* <div className="mt-auto p-4">
        <Card>
          <CardHeader className="p-2 pt-0 md:p-4">
            <CardTitle>Upgrade to Pro</CardTitle>
            <CardDescription>
              Unlock all features and get unlimited access to our support team.
            </CardDescription>
          </CardHeader>
          <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
            <Button size="sm" className="w-full">
              Upgrade
            </Button>
          </CardContent>
        </Card>
      </div>

       */}
    </div>
  );
};

export { Sidebar };
