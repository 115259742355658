import React from "react";

import { Card, CardHeader, CardContent } from "./ui/card";
import { Separator } from "./ui/separator";
import { Toaster } from "./ui/sonner.jsx";


import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";

import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "./ui/resizable";

const DidYouKnow = (props) => {
  return (
    <div className="mt-auto p-4 p-2 pt-0 md:p-4">

    <Collapsible >
      <CollapsibleTrigger asChild>
        <Button variant="outline" className="mb-4">
          💡
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="w-full max-w-xs">
          <Carousel className="w-full max-w-xs">
            <CarouselContent>
              {[
                {
                  title: "Did You Know 1",
                  description: "This is an interesting fact about topic 1.",
                  icon: "🌟",
                },
                {
                  title: "Did You Know 2",
                  description: "This is an interesting fact about topic 2.",
                  icon: "🔥",
                },
                {
                  title: "Did You Know 3",
                  description: "This is an interesting fact about topic 3.",
                  icon: "💡",
                },
                {
                  title: "Did You Know 4",
                  description: "This is an interesting fact about topic 4.",
                  icon: "🚀",
                },
                {
                  title: "Did You Know 5",
                  description: "This is an interesting fact about topic 5.",
                  icon: "🌍",
                },
              ].map((item, index) => (
                <CarouselItem key={index}>
                  <div className="p-1">
                    <Card className="h-full w-full">
                      <CardHeader className="flex items-center justify-between p-4">
                        <span className="text-2xl">{item.icon}</span>
                        <span className="text-xl font-bold">{item.title}</span>
                      </CardHeader>
                      <CardContent className="flex flex-col items-center justify-center p-6">
                        <p className="text-base text-center">
                          {item.description}
                        </p>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </CollapsibleContent>
    </Collapsible>

    </div>
  );
};

export { DidYouKnow };
