import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getNestedKeys = (obj, prefix = "") =>
  Object.keys(obj).reduce((res, key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}__${key}` : key;
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return res.concat(getNestedKeys(value, prefixedKey));
    } else {
      return res.concat(prefixedKey);
    }
  }, []);

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((res, key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}__${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {

      if (value.$$typeof || value.type || value.key || value.ref) {
        // Skip React-specific properties
        return res;
      }
      Object.assign(res, flattenObject(value, prefixedKey));
    } else if (Array.isArray(value)) {
      res[prefixedKey] = value
        .map((item) => {
          if (typeof item === "object" && item !== null) {
            return JSON.stringify(item);
          } else if (isMongoDate(item)) {
            return formatMongoDate(item);
          }
          return item;
        })
        .join(", ");
    } else if (isMongoDate(value)) {
      res[prefixedKey] = formatMongoDate(value);
    } else if (typeof value === "symbol") {
      res[prefixedKey] = value.toString();
    } else {
      res[prefixedKey] = value !== null && value !== undefined ? value : "";
    }
    

    return res;
  }, {});

export const isMongoDate = (value) => {
  try {
    if (
      typeof value !== "string" ||
      value.length < 10 ||
      !value.includes("T")
    ) {
      return false;
    }

    const date = new Date(value);
    return !isNaN(date.getTime());
  } catch (error) {
    console.error("Error parsing date:", error);
    return false;
  }
};

export const formatMongoDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }

    const convertUTCToLocal = (date) => {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    const localDate = convertUTCToLocal(date);

    const isMidnight = date.getUTCHours() === 18 && date.getUTCMinutes() === 30;

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    if (!isMidnight) {
      options.hour = "2-digit";
      options.minute = "2-digit";
    }

    return localDate.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString; // Return the original string if formatting fails
  }
};
