// src/DataTable.js

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';

const DataTable = ({ data, columnDefs, columnDefsAdvanced }) => {
  const tableRef = useRef();

  useEffect(() => {

    // console.log('Data:', data);
    // console.log('ColumnDefs:', columnDefs);
    // console.log('ColumnDefsAdvanced:', columnDefsAdvanced);

    const columns = columnDefs || Object.keys(data[0]).map(key => ({ title: key, data: key }));

    const table = $(tableRef.current).DataTable({
      data: data,
      columns: columns,
      columnDefs: columnDefsAdvanced.map(def => ({
        ...def,
        render: def.render ? eval(`(${def.render})`) : undefined,  // Fix eval to parse the function string correctly

      })) || []
    
    });

    return () => {
      table.destroy();
    };
  }, [data, columnDefs, columnDefsAdvanced]);

  return (
    <div>
      <table ref={tableRef} className="display" width="100%"></table>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.object),
  columnDefsAdvanced: PropTypes.arrayOf(PropTypes.object)
};

export default DataTable;


// usage example
/*

const App = () => {
  const sampleData = {
    data: [
      { Name: "Tiger Nixon", Position: "System Architect", Office: "Edinburgh", Age: "61", "Start date": "2011/04/25", Salary: "$320,800" },
      { Position: "Accountant", Name: "Garrett Winters1", Office: "Tokyo", Age: "63", "Start date": "2011/07/25", Salary: "$170,750" }
    ],
    columnDefs: [
      { title: "Name", data: "Name" },
      { title: "Position", data: "Position" },
      { title: "Office", data: "Office" },
      { title: "Age", data: "Age" },
      { title: "Start date", data: "Start date" },
      { title: "Salary", data: "Salary" }
    ],
    columnDefsAdvanced: [
      {
        targets: 3,
        type: 'num',
        render: function(data, type, row) {
          return data + ' years';
        }
      },
      {
        targets: 4,
        type: 'date',
        render: function(data, type, row) {
          return new Date(data).toLocaleDateString();
        }
      }
    ]
  };

  return (
    <div>
      <DataTable
        data={sampleData.data}
        columnDefs={sampleData.columnDefs}
        columnDefsAdvanced={sampleData.columnDefsAdvanced}
      />
    </div>
  );
};

*/