import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const options = [
  {
    key: "English",
    text: "English",
    value: "English",
    label: { color: "red", empty: true, circular: false },
  },
  { key: "French", text: "French", value: "French" },
  { key: "Spanish", text: "Spanish", value: "Spanish" },
  { key: "German", text: "German", value: "German" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
];

class DropdownExampleAllowAdditions extends Component {
  state = { options };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValues: value });

  render() {
    const { currentValues } = this.state;

    return (
      <Dropdown
        options={this.state.options}
        placeholder="Choose Languages"
        search
        selection
        fluid
        multiple
        allowAdditions
        value={currentValues}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}

export default DropdownExampleAllowAdditions;
