import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const ContactInfo = ({ mobile, email }) => {
  return (
    <div>
      {mobile && (
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<WhatsAppIcon />}
            onClick={() =>
              window.open(
                `whatsapp://send?phone=91${mobile}&text=${encodeURIComponent("Test")}`
              )
            }
          >
            {""}
          </Button>
        </div>
      )}
      {email && (
        <div>
          <a href={`mailto:${email}`}>
            <EmailIcon /> {email}
          </a>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;
