export const sampleData = [
    {
        "company": "Tata Consultancy Services",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "contact@tcs.com",
        "mobile": "9876543210",
        "dateOfIncorporation": "1968-04-01"
    },
    {
        "company": "Infosys",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "info@infosys.com",
        "mobile": "8765432109",
        "dateOfIncorporation": "1981-07-02"
    },
    {
        "company": "Wipro",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "support@wipro.com",
        "mobile": "7654321098",
        "dateOfIncorporation": "1945-12-29"
    },
    {
        "company": "Reliance Industries",
        "Industry": "Conglomerate",
        "Activity": "Diversified Operations",
        "email": "connect@ril.com",
        "mobile": "6543210987",
        "dateOfIncorporation": "1973-05-08"
    },
    {
        "company": "HDFC Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "customer.care@hdfcbank.com",
        "mobile": "5432109876",
        "dateOfIncorporation": "1994-08-30"
    },
    {
        "company": "ICICI Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "support@icicibank.com",
        "mobile": "4321098765",
        "dateOfIncorporation": "1994-01-05"
    },
    {
        "company": "Bharti Airtel",
        "Industry": "Telecommunications",
        "Activity": "mobile Network Operations",
        "email": "care@airtel.com",
        "mobile": "3210987654",
        "dateOfIncorporation": "1995-07-07"
    },
    {
        "company": "Larsen & Toubro",
        "Industry": "Construction",
        "Activity": "Infrastructure Development",
        "email": "info@lnt.com",
        "mobile": "2109876543",
        "dateOfIncorporation": "1938-12-19"
    },
    {
        "company": "Mahindra & Mahindra",
        "Industry": "Automobile",
        "Activity": "Vehicle Manufacturing",
        "email": "customercare@mahindra.com",
        "mobile": "1098765432",
        "dateOfIncorporation": "1945-10-02"
    },
    {
        "company": "Adani Enterprises",
        "Industry": "Conglomerate",
        "Activity": "Energy, Resources, and Infrastructure",
        "email": "info@adani.com",
        "mobile": "9988776655",
        "dateOfIncorporation": "1988-08-02"
    },
    {
        "company": "Tata Consultancy Services",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "contact@tcs.com",
        "mobile": "9876543210",
        "dateOfIncorporation": "1968-04-01"
    },
    {
        "company": "Infosys",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "info@infosys.com",
        "mobile": "8765432109",
        "dateOfIncorporation": "1981-07-02"
    },
    {
        "company": "Wipro",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "support@wipro.com",
        "mobile": "7654321098",
        "dateOfIncorporation": "1945-12-29"
    },
    {
        "company": "Reliance Industries",
        "Industry": "Conglomerate",
        "Activity": "Diversified Operations",
        "email": "connect@ril.com",
        "mobile": "6543210987",
        "dateOfIncorporation": "1973-05-08"
    },
    {
        "company": "HDFC Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "customer.care@hdfcbank.com",
        "mobile": "5432109876",
        "dateOfIncorporation": "1994-08-30"
    },
    {
        "company": "ICICI Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "support@icicibank.com",
        "mobile": "4321098765",
        "dateOfIncorporation": "1994-01-05"
    },
    {
        "company": "Bharti Airtel",
        "Industry": "Telecommunications",
        "Activity": "mobile Network Operations",
        "email": "care@airtel.com",
        "mobile": "3210987654",
        "dateOfIncorporation": "1995-07-07"
    },
    {
        "company": "Larsen & Toubro",
        "Industry": "Construction",
        "Activity": "Infrastructure Development",
        "email": "info@lnt.com",
        "mobile": "2109876543",
        "dateOfIncorporation": "1938-12-19"
    },
    {
        "company": "Mahindra & Mahindra",
        "Industry": "Automobile",
        "Activity": "Vehicle Manufacturing",
        "email": "customercare@mahindra.com",
        "mobile": "1098765432",
        "dateOfIncorporation": "1945-10-02"
    },
    {
        "company": "Adani Enterprises",
        "Industry": "Conglomerate",
        "Activity": "Energy, Resources, and Infrastructure",
        "email": "info@adani.com",
        "mobile": "9988776655",
        "dateOfIncorporation": "1988-08-02"
    },
    {
        "company": "Tata Consultancy Services",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "contact@tcs.com",
        "mobile": "9876543210",
        "dateOfIncorporation": "1968-04-01"
    },
    {
        "company": "Infosys",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "info@infosys.com",
        "mobile": "8765432109",
        "dateOfIncorporation": "1981-07-02"
    },
    {
        "company": "Wipro",
        "Industry": "IT Services",
        "Activity": "Software Development",
        "email": "support@wipro.com",
        "mobile": "7654321098",
        "dateOfIncorporation": "1945-12-29"
    },
    {
        "company": "Reliance Industries",
        "Industry": "Conglomerate",
        "Activity": "Diversified Operations",
        "email": "connect@ril.com",
        "mobile": "6543210987",
        "dateOfIncorporation": "1973-05-08"
    },
    {
        "company": "HDFC Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "customer.care@hdfcbank.com",
        "mobile": "5432109876",
        "dateOfIncorporation": "1994-08-30"
    },
    {
        "company": "ICICI Bank",
        "Industry": "Banking",
        "Activity": "Retail Banking",
        "email": "support@icicibank.com",
        "mobile": "4321098765",
        "dateOfIncorporation": "1994-01-05"
    },
    {
        "company": "Bharti Airtel",
        "Industry": "Telecommunications",
        "Activity": "mobile Network Operations",
        "email": "care@airtel.com",
        "mobile": "3210987654",
        "dateOfIncorporation": "1995-07-07"
    },
    {
        "company": "Larsen & Toubro",
        "Industry": "Construction",
        "Activity": "Infrastructure Development",
        "email": "info@lnt.com",
        "mobile": "2109876543",
        "dateOfIncorporation": "1938-12-19"
    },
    {
        "company": "Mahindra & Mahindra",
        "Industry": "Automobile",
        "Activity": "Vehicle Manufacturing",
        "email": "customercare@mahindra.com",
        "mobile": "1098765432",
        "dateOfIncorporation": "1945-10-02"
    },
    {
        "company": "Adani Enterprises",
        "Industry": "Conglomerate",
        "Activity": "Energy, Resources, and Infrastructure",
        "email": "info@adani.com",
        "mobile": "9988776655",
        "dateOfIncorporation": "1988-08-02"
    }
]

