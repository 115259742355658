import CompanyCard from "./GenericCard";

const CustomComponent = ({ children, ...props }) => {
  return (
    <>
      <CompanyCard {...props}>{children}</CompanyCard>
    </>
  );
};

export default CustomComponent