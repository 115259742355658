import React from "react";
import { Box, Typography, Link, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";

const Menu = ({
  templateOptions,
  selectedTemplateValue,
  handleTemplateChange,
  setQueryParams,
  children,
  loading, // Add a loading prop
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {Object.entries(templateOptions).map(([group, options]) => (
        <Box key={group} sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ pl: 2, mt: 2, mb: 1 }} className="">
            {group.toUpperCase()}
          </Typography>

          {options.map((option) => (
            <Link
              data-selected={option.value === selectedTemplateValue}
              data-key={option.value}
              key={option.value}
              className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-hover-light dark:hover:bg-hover-dark"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleTemplateChange({
                  target: { value: option.value },
                });
                document.querySelectorAll("[data-selected]").forEach((link) => {
                  link.removeAttribute("data-selected");
                });
                e.currentTarget.setAttribute("data-selected", "true");
              }}
              sx={{
                padding: "8px 16px",
                textDecoration: "none",
                color: (theme) => theme.palette.text.primary,
                display: "block",
                backgroundColor: (theme) =>
                  option.value === selectedTemplateValue
                    ? theme.palette.action.selected
                    : "transparent",
                '&[data-selected="true"]': {
                  fontWeight: "bold",
                },
                "&:hover": {
                  backgroundColor: (theme) =>
                    option.value === selectedTemplateValue
                      ? theme.palette.action.selected
                      : theme.palette.action.hover,
                },
              }}
            >
              {option.label}
            </Link>
          ))}
        </Box>
      ))}
      {children}
    </Box>
  );
};

export { Menu };
