import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_SelectCheckbox } from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";

import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { sampleData } from "../data";
import { Box } from "@mui/material";
import CompanyCard from "../components/cards/CompanyCard"


const CardViewMRT = () => {
  const [tableInstance, setTableInstance] = useState(null);

  const columns = useMemo(
    () => [
      { accessorKey: "Company Name", header: "Company Name" },
      { accessorKey: "Industry", header: "Industry" },
      { accessorKey: "Activity", header: "Activity" },
      { accessorKey: "Email", header: "Email" },
      { accessorKey: "Mobile", header: "Mobile" },
      { accessorKey: "Date of Incorporation", header: "Date of Incorporation" },
    ],
    []
  );

  const [sorting, setSorting] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  return (
    <div className="container mx-auto py-10">
      <div className="flex items-center py-4">
        <Input
          placeholder="Search..."
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <Button
          onClick={() => {
            if (!tableInstance) return;
            const isAnyRowSelected =
              tableInstance.getIsSomeRowsSelected() ||
              tableInstance.getIsAllRowsSelected();
            tableInstance.toggleAllPageRowsSelected(!isAnyRowSelected);
          }}
          className="ml-4"
        >
          {(tableInstance && tableInstance?.getIsSomeRowsSelected()) ||
          tableInstance?.getIsAllRowsSelected()
            ? "Deselect All"
            : "Select All"}
        </Button>
      </div>

      <MaterialReactTable
        columns={columns}
        data={sampleData}
        enableRowSelection
        state={{
          sorting,
          globalFilter,
        }}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        muiTableProps={{
          localization: MRT_Localization_EN,
          onTableChange: setTableInstance,
        }}
        muiTableBodyProps={({ table }) => {
          if (!tableInstance) {
            setTableInstance(table);
          }

          return {
            children: (
              <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {table.getRowModel().rows.map((row) => (
                  <CompanyCard
                    key={row.id}
                    company={row.original}
                    isSelected={!!table.getState().rowSelection[row.id]}
                    onSelect={() => {
                      const isSelected = table.getState().rowSelection[row.id];
                      table.setRowSelection({
                        ...table.getState().rowSelection,
                        [row.id]: !isSelected,
                      });
                    }}
                  />
                ))}
              </Box>
            ),
            onRowClick: (row) => {
              const isSelected = table.getState().rowSelection[row.id];
              table.setRowSelection({
                ...table.getState().rowSelection,
                [row.id]: !isSelected,
              });
            },
          };
        }}
        enableTableHead={false}
        enablePagination={true}
        muiTablePaginationProps={{
          rowsPerPageOptions: [6, 12, 24],
          initialState: { pageSize: 20 },
        }}
      />
    </div>
  );
};

export { CardViewMRT };
