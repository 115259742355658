import ContactInfo from "./ContactInfo";

const updateTableData = (tableData, resultData) => {
  return tableData.map((item) => {
    const matchingResult = resultData.find(
      (res) => res.row_id.$oid === item._id
    );

    if (matchingResult) {
      return {
        ...item,
        isSelected: false, // Unselect the row

        contact: (
          <ContactInfo
            mobile={matchingResult.mobile}
            email={matchingResult.email}
          />
        ),
      };
    }

    return {
      ...item,
      isSelected: false, // Unselect the row
    };
  });
};

const handleDataUpdate = (tableData, resultData, setTableData) => {
  if (!resultData) return false;

  const updatedData = tableData.map((item) => {
    const matchingResult = resultData.find(
      (res) => res.row_id.$oid === item._id
    );

    if (matchingResult) {
      return {
        ...item,
        mobile: matchingResult.mobile,
        emailAddress: matchingResult.email,
        email: matchingResult.email,
        contact: (
          <ContactInfo
            mobile={matchingResult.mobile}
            email={matchingResult.email}
          />
        ),
      };
    }

    return item;
  });

  setTableData((prevData) =>
    prevData.map(
      (item) =>
        updatedData.find((updatedItem) => updatedItem._id === item._id) || item
    )
  );
};

export { updateTableData, handleDataUpdate };
