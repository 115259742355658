// src/MaterialDataTable.js

import React, { useMemo, useEffect, useRef, useState } from "react";
import {
  MaterialReactTable,
  createRow,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
  useMaterialReactTable,
  getMRT_RowSelectionHandler,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ActionMenuItem,
  MRT_SelectCheckbox,
} from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  TextField,
  lighten,
} from "@mui/material";
import { toast } from "sonner";

import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { CalendarDays } from "lucide-react";

import useStore from "../store";
import { useUpdateCredits } from "../index";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../components/ui/hover-card";

import {
  updateTableData,
  handleDataUpdate,
} from "../components/UpdateTableData";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

import { fetchAPI, fetchPublicAPI, mutateAPI, postAPI } from "../lib/FetchAPI";

import PropTypes from "prop-types";
import { ExportToCsv } from "material-react-table";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import { useTypewriterEffect } from "../lib/TextAnimation";
// export const updateCredits = async ({ credits }) => {

//   // const { userDetails, setUserDetails } = useStore();

//   if(!credits)
//     return false;
//   console.log(`Updating credits ${credits}`);
//   console.log(userDetails)
//   // setUserDetails({...userDetails, "credits": credits })
// };
import { animate } from "framer-motion";
import { Visibility } from "semantic-ui-react";

import {getNestedKeys, flattenObject, formatMongoDate, isMongoDate} from "../lib/utils"

const MaterialDataTable = ({
  data,
  columns,
  message,
  handleTemplateChange,
  tableInstance,
  ...props
}) => {
  const updateCredits = useUpdateCredits();
  const triggerTypewriterEffect = useTypewriterEffect();

  const unselectAll = () => {
    if (!tableInstance) return;

    /* ToDo animate selected unlocked rows */
    //tableInstance.resetRowSelection();
    const selectedRows = tableInstance.getSelectedRowModel().rows;

    // Extract the `_id` field from the selected rows
    const selectedRowIds = selectedRows.map((row) => row.original._id);

    // Animation logic using Framer Motion
    selectedRowIds.forEach((rowId, index) => {
      const rowElement = document.getElementById(`company-row-${rowId}`);

      if (rowElement) {
        triggerTypewriterEffect(
          rowElement.querySelector(".email"),
          index * 0.4
        );

        triggerTypewriterEffect(
          rowElement.querySelector(".mobile"),
          index * 0.7
        );
      }
      setTimeout(() => {
        tableInstance.resetRowSelection();
      }, 1000 * selectedRowIds.length);
    });
  };


  const getContactColumn = () => ({
    accessorKey: "contact",
    header: "Contact",
    Cell: ({ row }) => (
      <div>
        {row.original.mobile && row.original.mobile != "Available" && (
          <div>
            {/* <PhoneIcon /> {row.original.mobile} */}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<WhatsAppIcon />}
              title={`WhatsApp ${row.original.mobile}`}
              onClick={() =>
                window.open(
                  `whatsapp://send?phone=91${
                    row.original.mobile
                  }&text=${encodeURIComponent("Test")}`
                )
              }
            >
              {` `}
              {/* {`${row.original.mobile}`} */}
            </Button>
          </div>
        )}
        {row.original.email && row.original.email != "Available" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EmailIcon />}
              title={`Email ${row.original.email}`}
              onClick={() => window.open(`mailto:${row.original.email}`)}
            ></Button>
          </div>
        )}
      </div>
    ),
    // muiTableBodyCellProps: { sx: { whiteSpace: "nowrap" } },
  });

  const getContactEmptyColumn = () => ({
    accessorKey: "save_contact",
    header: "SAVE",
    Cell: ({ row }) => (
      <div>
        {!row.original?.saved ? (
          <div>
            {/* <PhoneIcon /> {row.original.mobile} */}
            <Button
              variant="contained"
              color="primary"
              // startIcon={<WhatsAppIcon />}
              onClick={() => handleUnlockRows([row])} // Wrap row.original in an array
            >
              {`Save`}
            </Button>
          </div>
        ) : null}
        {/* 
        {
          <div>
            <a href={`mailto:${row.original.email}`}>
              {" Save Email"}
              <EmailIcon /> {row.original.email}
            </a>
          </div>
        }
         */}
      </div>
    ),
    // muiTableBodyCellProps: { sx: { whiteSpace: "nowrap" } },
  });

  const tableColumns = useMemo(() => {
    if (columns) {
      return columns.map((col) => ({
        ...col,
        Cell: col.Cell ? eval(col.Cell) : undefined,
        // enableHiding:
        //   col.accessorKey.startsWith("_") ||
        //   ["mobile", "email"].includes(col.accessorKey)
        //     ? true
        //     : undefined,
        // muiTableBodyCellProps:
        //   col.accessorKey.startsWith("_") ||
        //   ["mobile", "email"].includes(col.accessorKey)
        //     ? { sx: { flex: 1, display: "none" } }
        //     : undefined,
        // muiTableHeadCellProps:
        //   col.accessorKey.startsWith("_") ||
        //   ["mobile", "email"].includes(col.accessorKey)
        //     ? { sx: { display: "none" } }
        //     : undefined,
        filterFn: "contains",
        // sx: { flex: 0, display: "none" },
        // enableHiding: true,
      }));
      // .concat(
      //   columns.some(
      //     (col) => col.accessorKey === "mobile" || col.accessorKey === "email"
      //   )
      //     ? [getContactColumn()]
      //     : []
      // )
    }

    /* show / hide tablehead columns */
    const keys = getNestedKeys(data[0]);
    return keys
      .map((key) => ({
        accessorKey: key,
        header: key.replace("_", " "),
        aggregationFn: ["count", "mean", "median", "min", "max"],
        enableHiding: true,
        // muiTableBodyCellProps:
        //   key.startsWith("_") || ["mobile", "email"].includes(key)
        //     ? { sx: { display: "none" } }
        //     : undefined,
        muiTableHeadCellProps:
          1 || key.startsWith("_") || ["mobile", "email"].includes(key)
            ? { sx: { display: "none" } }
            : undefined,
        filterFn: "contains",
      }))
      .concat(
        keys.includes("mobile") || keys.includes("email")
          ? // ? [getContactColumn()]
            []
          : []
      );
    // .concat([getContactEmptyColumn()]);
  }, [columns, data]);

  // const tableData = useMemo(
  //   () => data.map((item) => flattenObject(item)),
  //   [data]
  // );

  const [tableData, setTableData] = useState(
    data.map((item) => flattenObject(item))
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const unlockRows = async (filteredRows) => {
    // await getCredits();
    const selectedTemplate =
      document
        .querySelector("[data-selected='true']")
        ?.getAttribute("data-key") || "";

    if (!selectedTemplate) {
      toast.error("Select a data source!");
      return false;
    }

    // alert(selectedTemplate);
    // toast.info(selectedTemplate)

    const payload = {
      action: "unlock",
      config: selectedTemplate,

      data: {
        rows: filteredRows,
      },
    };

    try {
      const result = await postAPI({
        // endpoint: "/your-endpoint",  // Replace with your actual endpoint
        body: payload,
        description: `${"Unlocking Rows: " + filteredRows.length}`,
      });

      console.log("result:", result);

      handleDataUpdate(tableData, result.data, setTableData);

      updateCredits({ credits: result?.credits });

      unselectAll();
      // const updatedData = updateTableData(tableData, result.data);

      // const updatedData = tableData.map((item) => {
      //   const matchingResult = result.data.find(
      //     (res) => res.row_id.$oid === item._id
      //   );

      //   if (matchingResult) {
      //     return {
      //       ...item,
      //       contact: (
      //         <div>
      //           {matchingResult.mobile && (
      //             <div>
      //               <Button
      //                 variant="contained"
      //                 color="primary"
      //                 startIcon={<WhatsAppIcon />}
      //                 onClick={() =>
      //                   window.open(
      //                     `whatsapp://send?phone=91${
      //                       matchingResult.mobile
      //                     }&text=${encodeURIComponent("Test")}`
      //                   )
      //                 }
      //               >
      //                 {""}
      //               </Button>
      //             </div>
      //           )}
      //           {matchingResult.email && (
      //             <div>
      //               <a href={`mailto:${matchingResult.email}`}>
      //                 <EmailIcon /> {matchingResult.email}
      //               </a>
      //             </div>
      //           )}
      //         </div>
      //       ),
      //     };
      //   }

      //   return item;
      // });

      //setTableData(updatedData);

      // toast.info("View Contacts", {
      //   action: {
      //     label: "View Contacts",
      //     onClick: () => {
      //       handleTemplateChange({
      //         target: { value: "my-contacts" },
      //       });
      //     },
      //   },
      // });

      // toast.success(JSON.stringify(result), {
      //   duration: Infinity,
      //   closeButton: true,
      // });
    } catch (error) {
      console.error("Error:", error);
      toast.error(JSON.stringify(error), {
        duration: Infinity,
        closeButton: true,
      });
    }
  };

  const handleUnlockRows = async (rows) => {
    const rowData = rows.map((row) => row.original);
    const filteredRows = rowData.filter((row) => row._id).map((row) => row._id);

    // Create a copy of the current table data
    const updatedData = tableData.map((item) => {
      if (filteredRows.includes(item._id)) {
        return {
          ...item,
          id: `row-${item?._id}`, // Assuming _id is a unique identifier

          contact: <span>Loading...</span>, // Programmatically update the contact cell value
        };
      }
      return item;
    });

    // Update the state to trigger a re-render of the affected rows

    // setTableData(updatedData);

    if (rowData.length < 1 || filteredRows.length < 1) {
      toast.error("No valid rows to unlock. Please select atleast one row", {
        duration: Infinity,
        closeButton: false,
        action: {
          label: "Okay",
        },
      });
    } else {
      unlockRows(filteredRows);
    }
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const isFirstRender = useRef(true);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [density, setDensity] = useState("compact");
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [sorting, setSorting] = useState([]);

  //load state from local storage
  useEffect(() => {
    const columnFilters = sessionStorage.getItem("mrt_columnFilters_table_1");
    const columnVisibility = sessionStorage.getItem(
      "mrt_columnVisibility_table_1"
    );
    const density = sessionStorage.getItem("mrt_density_table_1");
    const globalFilter = sessionStorage.getItem("mrt_globalFilter_table_1");
    const showGlobalFilter = sessionStorage.getItem(
      "mrt_showGlobalFilter_table_1"
    );
    const showColumnFilters = sessionStorage.getItem(
      "mrt_showColumnFilters_table_1"
    );
    const sorting = sessionStorage.getItem("mrt_sorting_table_1");

    if (columnFilters) {
      setColumnFilters(JSON.parse(columnFilters));
    }
    if (columnVisibility) {
      setColumnVisibility(JSON.parse(columnVisibility));
    }
    if (density) {
      setDensity(JSON.parse(density));
    }
    if (globalFilter) {
      setGlobalFilter(JSON.parse(globalFilter) || undefined);
    }
    if (showGlobalFilter) {
      setShowGlobalFilter(JSON.parse(showGlobalFilter));
    }
    if (showColumnFilters) {
      setShowColumnFilters(JSON.parse(showColumnFilters));
    }
    if (sorting) {
      setSorting(JSON.parse(sorting));
    }
    isFirstRender.current = false;
  }, []);

  //save states to local storage
  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem(
      "mrt_columnFilters_table_1",
      JSON.stringify(columnFilters)
    );
  }, [columnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem(
      "mrt_columnVisibility_table_1",
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem("mrt_density_table_1", JSON.stringify(density));
  }, [density]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem(
      "mrt_globalFilter_table_1",
      JSON.stringify(globalFilter ?? "")
    );
  }, [globalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem(
      "mrt_showGlobalFilter_table_1",
      JSON.stringify(showGlobalFilter)
    );
  }, [showGlobalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem(
      "mrt_showColumnFilters_table_1",
      JSON.stringify(showColumnFilters)
    );
  }, [showColumnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    sessionStorage.setItem("mrt_sorting_table_1", JSON.stringify(sorting));
  }, [sorting]);

  const resetState = () => {
    sessionStorage.removeItem("mrt_columnFilters_table_1");
    sessionStorage.removeItem("mrt_columnVisibility_table_1");
    sessionStorage.removeItem("mrt_density_table_1");
    sessionStorage.removeItem("mrt_globalFilter_table_1");
    sessionStorage.removeItem("mrt_showGlobalFilter_table_1");
    sessionStorage.removeItem("mrt_showColumnFilters_table_1");
    sessionStorage.removeItem("mrt_sorting_table_1");
    window.location.reload();
  };

  const [data2, setData2] = useState([]);
  const [prevData, setPrevData] = useState([]);

  const handleCallStatusChange = (id, status, row) => {
    // console.log(id)
    console.log(status);
    console.log(row);
    setData2((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, callStatus: status } : item
      )
    );
  };

  //
  return (
    <Box
      className="flex-grow overflow-y-auto overflow-x-auto flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
      style={{
        height: "calc(80vh - 60px - 40px)",
      }}
    >
      {/*       
      <Button
        variant="outline"
        onClick={() =>
          toast("Event has been created", {
            description: "Sunday, December 03, 2023 at 9:00 AM",
            action: {
              label: "Undo",
              onClick: () => console.log("Undo"),
            },
          })
        }
      >
        Show Toast
      </Button> */}

      <MaterialReactTable
        className=""
        key="unique-key-that-does-not-change"
        columns={tableColumns}
        data={tableData}
        // editMode={"row"}
        // enableRowEdit

        // positionToolbarAlertBanner={"bottom"}
        // onEditingRowSave={({ rowData }) => handleCreateNewRow(rowData)}
        // onCreatingRowSave={({ rowData }) => handleCreateNewRow(rowData)}
        // enableFacetedValues={true}
        enableColumnFilterModes={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={true}
        enableColumnVirtualization={true}
        enableRowVirtualization={true}
        enableGrouping={false}
        enablePinning
        enableRowSelection
        // enableRowSelection={(row) => {
        //   return row?.email || (row?.mobile && row?.mobile != "Available");
        // }}

        enablePagination={false}
        enableGlobalFilter={false}
        enableColumnResizing={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableColumnOrdering={false}
        enableStickyHeader={true}
        // onClick={(event, row) => row.toggleRowSelected()}
        muiPaginationProps={{
          color: "primary",
          rowsPerPageOptions: [25, 100],
          shape: "rounded",
          variant: "outlined",
          showRowsPerPage: false,
        }}
        initialState={{
          showColumnFilters: true,
          expanded: false,
          globalFilter: true,
          pagination: { pageSize: 9 },
          columnPinning: {
            // left: ["mrt-row-select"],
            // right: ["mrt-row-actions"],
          },
        }}
        onColumnFiltersChange={setColumnFilters}
        onColumnVisibilityChange={setColumnVisibility}
        onDensityChange={setDensity}
        onGlobalFilterChange={setGlobalFilter}
        onShowColumnFiltersChange={setShowColumnFilters}
        onShowGlobalFilterChange={setShowGlobalFilter}
        onSortingChange={setSorting}
        state={{
          columnFilters,
          columnVisibility,
          density,
          globalFilter,
          showColumnFilters,
          showGlobalFilter,
          sorting,
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            "& .MuiBox-root": {
              backgroundColor: "var(--global-bg-color)", // Apply global background color to wrapper
              color: "var(--global-text-color)", // Apply global text color to wrapper
            },
            borderRadius: "0",
            border: "none",
            boxShadow: "none",
            backgroundColor: "var(--global-bg-color)", // Apply global background color
            color: "var(--global-text-color)", // Apply global text color
            // minWidth: "70vw",
            width: "100%",
            // maxWidth: "100%",
          },
        }}
        minSize={2}
        maxSize={2}
        size={5}
        muiTableFooterProps={{
          sx: {
            backgroundColor: "var(--global-bg-color)", // Apply global background color
            color: "var(--global-text-color)", // Apply global text color
            borderTop: "1px solid var(--border)", // Consistent border for footer
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            // backgroundColor: "transparent",
            border: "none",
            whiteSpace: "normal",
            // wordWrap: "break-word",
            fontSize: "1rem",
            margin: "0px",
            padding: "2px",
            flex: 0,
          },
        }}
        muiTableHeadCellProps={{
          // sx:{
          //   display: "none !important",
          //   Visibility: "hidden !important"
          // }

          sx: {
            backgroundColor: "var(--global-bg-color)", // Apply global background color to header
            color: "var(--global-text-color)", // Apply global text color to header
            border: "none", // Ensure flat borders in header
          },
        }}
        muiTableBodyRowProps={({ row, staticRowIndex, table }) => ({
          sx: {
            backgroundColor: "transparent", // Ensure no background color on rows
            "&:hover": {
              backgroundColor: "transparent", // Remove hover background color
            },
            border: "none", // Consistent border for rows
            cursor: "pointer",
          },

          // onClick: (event) =>
          // getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event),
        })}
        layoutMode={"grid-no-grow"}
        memoMode="rows"
        // muiTablePaperProps={{
        //   elevation: 24,
        //   sx: {
        //     borderRadius: "0",
        //     border: "none",
        //     boxShadow: "none",
        //   },
        // }}

        {...props}
        renderTopToolbar={({ table }) => {
          const handleDeactivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("deactivating " + row.getValue("name"));
            });
          };

          const handleActivate = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("activating " + row.getValue("name"));
            });
          };

          const handleContact = () => {
            table.getSelectedRowModel().flatRows.map((row) => {
              alert("contact " + row.getValue("name"));
            });
          };

          return (
            <Box
              sx={(theme) => ({
                backgroundColor: lighten(theme.palette.background.default, 0),
                display: "flex",
                gap: "0.5rem",
                p: "8px",
                // justifyContent: "space-between",
              })}
            >
              {/* <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.text.default,
                  display: "block",
                  gap: "0.5rem",
                  p: "8px",
                  alignItems: "left",
                })}
              > */}

              {/* <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} /> */}

              {/* <MRT_ShowHideColumnsButton table={table} /> */}
              {/* </Box> */}

              {/* <Box> */}
              {/* <Box sx={{ display: "flex", gap: "0.5rem" }}> */}
              {/*                   
                  <Button
                    color="error"
                    disabled={!table.getIsSomeRowsSelected()}
                    onClick={handleDeactivate}
                    variant="contained"
                  >
                    Deactivate
                  </Button>
                  <Button
                    color="success"
                    disabled={!table.getIsSomeRowsSelected()}
                    onClick={handleActivate}
                    variant="contained"
                  >
                    Activate
                  </Button> */}

              <HoverCard>
                <HoverCardTrigger asChild>
                  <div>
                    <Button
                      color="primary"
                      disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                      }
                      onClick={() =>
                        handleUnlockRows(table.getSelectedRowModel().rows)
                      }
                      variant="contained"
                    >
                      Unlock Selected
                    </Button>
                  </div>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="flex justify-between space-x-4">
                    {/* <Avatar>
                          <AvatarImage src="https://github.com/vercel.png" />
                          <AvatarFallback>VC</AvatarFallback>
                        </Avatar>
                        <div className="space-y-1">
                          <h4 className="text-sm font-semibold">@nextjs</h4>
                          <p className="text-sm">
                            The React Framework – created and maintained by
                            @vercel.
                          </p>
                          <div className="flex items-center pt-2">
                            <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                            <span className="text-xs text-muted-foreground">
                              Joined December 2021
                            </span>
                          </div>
                        </div> */}
                  </div>
                </HoverCardContent>
              </HoverCard>

              {/* 
                  <Button
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  ></Button>

                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    onClick={() =>
                      handleExportRows(table.getSelectedRowModel().rows)
                    }
                    startIcon={<FileDownloadDoneIcon />}
                    variant="outlined"
                  ></Button>
 */}
              {/* </Box> */}
              {/* </Box> */}
            </Box>
          );
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              padding: "10px",
              flexWrap: "wrap",
            }}
          >
            <Button onClick={resetState}>Reset State</Button>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => {
                //setCreatingRowIndex(table.getRowModel().rows.length); //create new row at bottom of table
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
              }}
            ></Button>
            <Button
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            ></Button>

            {/* 
            <Button
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Export Page Rows
            </Button> */}

            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadDoneIcon />}
              variant="outlined"
            ></Button>
          </Box>
        )}

        // muiTableBodyProps={{
        //   sx: {
        //     backgroundColor: "var(--global-bg-color)",
        //     color: "var(--global-text-color)",
        //   },
        // }}
        // muiTableHeadCellProps={{
        //   sx: {
        //     backgroundColor: "var(--global-bg-color)",
        //     color: "var(--global-text-color)",
        //   },
        // }}
      />
    </Box>
  );
};

MaterialDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default MaterialDataTable;
