// src/lib/storageHelpers.js

  export const getLocalStorageItem = (key, defaultValue = null) => {
    const item = localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) : defaultValue;
    } catch {
      return item || defaultValue; // Return the item as is if JSON.parse fails
    }
  };
  
  export const setLocalStorageItem = (key, value) => {
    const valueToStore = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, valueToStore);
  };

  

  export const createStateWithStorage = (key, initialState) => (set) => ({
    [key]: getLocalStorageItem(key, initialState),
    [`set${capitalize(key)}`]: (value) => {
      setLocalStorageItem(key, value);
      set({ [key]: value });
    },
  });
  
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  