// src/index.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./components/theme-provider";

import useStore from "./store";

import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import App, { Menu } from "./App";
import Dashboard from "./components/Dashboard.jsx";
import DynamicComponents from "./lib/DynamicComponents";
import Example from "./InlineCRUD";
import FullFeaturedCrudGrid from "./lib/Mui-Datagrid-CRUD";
import { postAPI } from "./lib/FetchAPI";

const queryClient = new QueryClient();

// import { updateCredits } from "./lib/MaterialDataTable";

export const useGetCredits = () => {
  const { userDetails, setUserDetails, clearAuth } = useStore();
  const updateCredits = useUpdateCredits();

  const getCredits = async () => {
    const payload = {
      action: "get_credits",
    };

    try {
      const result = await postAPI({
        body: payload,
        description: `${"Getting Credits"}`,
        duration: 3,
      });

      console.log("result:", result?.credits);
      updateCredits(result?.credits);
    } catch (error) {
      console.error("Error:", error);
      toast.error(JSON.stringify(error), {
        duration: Infinity,
        closeButton: true,
      });
    }
  };

  return getCredits;
};

export const useUpdateCredits = () => {
  const { userDetails, setUserDetails } = useStore();

  const updateCredits = async (credits) => {
    if (credits === undefined || credits === null) return false;

    console.log(`Updating credits ${credits}`);
    console.log(userDetails);

    let newCredits;

    if (typeof credits === "number") {
      newCredits = credits;
    } else if (typeof credits === "object" && credits.credits !== undefined) {
      newCredits = credits.credits;
    } else {
      console.warn("Invalid credits format:", credits);
      return false;
    }

    setUserDetails({ ...userDetails, credits: newCredits });
    console.log(userDetails);
  };

  return updateCredits;
};

const TokenHandler = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get("jwt_token");

  const { setJwtToken, setUserDetails } = useStore();
  const getCredits = useGetCredits();

  useEffect(() => {
    if (tokenFromUrl) {
      setJwtToken(tokenFromUrl);

      const decodedToken = JSON.parse(atob(tokenFromUrl.split(".")[1]));

      // Calculate expiration date and time
      const expirationDate = new Date(decodedToken.exp * 1000); // Convert to milliseconds
      const localTime = expirationDate.toLocaleString(); // Convert to local time

      console.log(`Token expires on: ${localTime}`); // For debugging or further usage
      setUserDetails({ ...decodedToken, localExpiryTime: localTime });

      //getCredits()
      getCredits();

      window.location.href = "/discover/mca-live-recent";
    }
  }, [tokenFromUrl, setJwtToken, setUserDetails]);

  return null;
};

export function HowToVideo() {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(60.51699716713881% + 41px)",
        height: 0,
        width: "100%",
      }}
    >
      <iframe
        src="https://demo.arcade.software/kPmON3COhinsRalfppdZ?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=false"
        title="Cloata® App"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          colorScheme: "light",
        }}
      />
    </div>
  );
}

const RootComponent = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/_debug/:component"
          element={
            <>
              <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
                <DynamicComponents>
                  <p>This is a STATIC CHILD element</p>
                </DynamicComponents>
              </ThemeProvider>
            </>
          }
        />

        {/* <Route path="*" element={<Navigate to="/" />} /> */}

        <Route
          path="*"
          element={
            <QueryClientProvider client={queryClient}>
              <App queryClient={queryClient} />
              {/* <Menu></Menu> */}
            </QueryClientProvider>
          }
        />

        <Route path="/auth" element={<TokenHandler />} />
        <Route path="/how-to" element={<HowToVideo />} />

        <Route
          path="/dashboard"
          element={
            <QueryClientProvider client={queryClient}>
              <Dashboard>{/* <App queryClient={queryClient} /> */}</Dashboard>
            </QueryClientProvider>

            // <QueryClientProvider client={queryClient}>
            // <ApolloProvider client={apolloClient}>
            //   <App setEndpoint={setUri} queryClient={queryClient} />
            // </ApolloProvider>
            // </QueryClientProvider>
          }
        />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);

// const DynamicComponents = (props) => {
//   const { component } = useParams();
//   const location = useLocation();

//   let ComponentToRender;

//   try {
//     if (component) {
//       ComponentToRender = lazy(() => import(`./components/${component}`));
//     }
//   } catch (error) {
//     console.error("Component not found:", error);
//     return <div>Component not found</div>;
//   }

//   // Parse query parameters to get dynamic props
//   const searchParams = new URLSearchParams(location.search);
//   const dynamicProps = {};

//   searchParams.forEach((value, key) => {
//     dynamicProps[key] = value;
//   });

//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       {ComponentToRender ? (
//         <ComponentToRender {...dynamicProps} {...props}>
//           {props.children}
//           {dynamicProps.children && (
//             <div dangerouslySetInnerHTML={{ __html: dynamicProps.children }} />
//           )}
//         </ComponentToRender>
//       ) : (
//         <div>Component not found</div>
//       )}
//     </Suspense>
//   );

// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <QueryClientProvider client={queryClient}>
//     <ApolloProvider client={apolloClient}>
//       <App />
//       <Example />
//     </ApolloProvider>
//   </QueryClientProvider>
// );
