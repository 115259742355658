import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Button } from "../ui/button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckBoxIcon from "@mui/icons-material/CheckBoxSharp";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import DomainIcon from "@mui/icons-material/Domain";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PinDropIcon from "@mui/icons-material/PinDrop";
import MapIcon from "@mui/icons-material/Map";
import { Box, IconButton } from "@mui/material";
import { Skeleton } from "../ui/skeleton";
import {
  getNestedKeys,
  flattenObject,
  formatMongoDate,
  isMongoDate,
} from "../../lib/utils";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../ui/context-menu";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const CompanyCard = ({ row, isSelected, onSelect }) => {
  const [isUnlocked, setIsUnlocked] = useState(
    row?.email || (row?.mobile && row?.mobile != "Available")
  );

  return (
    <>
      <Card
        className={`mt-8 mb-0 rounded-lg shadow-lg hover:shadow-xl transition duration-50 flex flex-col  
    ${isSelected ? "bg-selected " : "bg-background hover:bg-secondary-200"}

    ${isUnlocked ? "bg-active " : ""}
    `}
        //   onClick={onSelect}
        id={`company-row-${row._id}`}
      >
        <CardHeader onClick={onSelect} className=" cursor-pointer">
          {!isUnlocked && (
            <Button
              className={`p-0 bg-background hover:bg-background justify-start ${
                isSelected ? "text-success" : "text-primary"
              }`}
            >
              {isSelected ? (
                <CheckBoxIcon fontSize="large" />
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="large" />
              )}
            </Button>
          )}

          <CardTitle className="text-xl font-semibold" title="Company Name">
            {row?.company}
          </CardTitle>
          <CardDescription title="Industry">{row?.Industry}</CardDescription>
        </CardHeader>

        <CardContent
          className="flex flex-col space-y-4 justify-between mt-auto"
          title=""
        >
          {/* <div className="flex flex-col space-y-2">
            {Object.entries(row).map(([key, value]) => (
              <div key={key} className="break-words">
                <small>{`${key}: ${value ?? "N/A"}`}</small>
              </div>
            ))}
          </div> */}

          <div className="flex flex-col space-y-2">
            {Object.entries(flattenObject(row)).map(([key, value]) => (
              <div key={key} className="break-words">
                <small>{`${key}: ${value ?? "N/A"}`}</small>
              </div>
            ))}
          </div>
        </CardContent>

        <CardFooter
          className="flex justify-between p-4 mt-auto"
          // onClick={onSelect}
        >
          <div
            className="flex items-center space-x-3 text-muted"
            title="Incorporation date of the Company"
          >
            <small>Date Added {row?.date_added}</small>
            <small>Last Modified {row?.last_modified}</small>
            {/* 
      <Button className="w-full p-8 text-primary bg-background" >
        {isSelected ? (
          <CheckBoxIcon fontSize="large" />
        ) : (
          <CheckBoxOutlineBlankIcon fontSize="large" />
        )}
      </Button> */}
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default CompanyCard;
