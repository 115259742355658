import { useAnimation, animate } from "framer-motion";

export const useTypewriterEffect = () => {
  const controls = useAnimation();

  const triggerTypewriterEffect = (element, delay = 300) => {

    if (!element) return false;
    
    element.style.visibility = "hidden";

    setTimeout(() => {
      const text = element.textContent; // Fetch the updated content after delay

      if (element) {
        element.style.visibility = "visible";

        element.innerHTML = text
          .split("")
          .map(
            (char, index) =>
              `<span id="char-${index}" style="display:inline-block; transform:scale(0.9); margin:0;">${char}</span>`
          )
          .join("");

        text.split("").forEach((char, index) => {
          const charElement = element.querySelector(`#char-${index}`);
          if (charElement) {
            animate(
              charElement,
              {
                opacity: [0, 1],
                scale: [0.1, 1.6, 1],
                // x: [20, 0],
              },
              {
                duration: 0.05,
                delay: index * 0.05 + delay,
              }
            );
          }
        });
      }
    }, 500); // Adjust the delay time as needed to ensure the content is updated
  };

  return triggerTypewriterEffect;
};
