// src/theme.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#222', // Replace with your desired color
    },
    background: {
      default: '#ffffff', // Tailwind CSS bg-gray-200
      paper: '#ffffff',   // Tailwind CSS bg-white
    },
    text: {
      primary: '#000000', // Tailwind CSS text-black
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ddd', // Replace with your desired color
    },
    background: {
      default: '#2d3748', // #5A6374
      paper: '#2d3748',   // #1a202c Tailwind CSS bg-gray-900
    },
    text: {
      primary: '#ffffff', // Tailwind CSS text-white
    },
  },
});

export { lightTheme, darkTheme };
