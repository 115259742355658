import React, { useState, useRef, useMemo, useEffect } from "react";

import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import { MenuOutlined } from "@mui/icons-material";

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];

export default function BasicSpeedDial() {
  return (
    <Box sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

const Layout = ({
  theme,
  setShowSidebar,
  showSidebar,
  sidebar,
  header,
  main,
}) => {
  return (
    <>
      <header
        className="flex items-center border-b bg-muted fixed top-0 left-0 right-0 z-10"
        theme={theme}
        setShowSidebar={setShowSidebar}
      >
        {header}
      </header>

      <div className="grid flex-grow h-[95vh] pt-[70px]  pb-[50px] w-full grid-cols-1 md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr] ">
        <div
          className={`border-r bg-muted/40 transition-all duration-300 ${
            showSidebar ? "block" : "hidden"
          }`}
          style={{ transition: "all 1s ease" }}
        >
          {sidebar}
        </div>

        <main className="flex flex-1 flex-col mb-[30px] min-w-[320px] md:min-w-[640px] lg:min-w-[1024px] p-6">
        {/* <main className="flex flex-1 flex-col mb-[30px] overflow-hidden min-w-[320px] md:min-w-[640px] lg:min-w-[1024px]"> */}
        {main}
          {/* <BasicSpeedDial /> */}
        </main>
{/* 
        <Paper
          sx={{ position: "fixed", zIndex: 100, bottom: 0, left: 0, right: 0 }}
          elevation={2}
        >
          <BottomNavigation showLabels>
            <BottomNavigationAction
              label="Toggle Sidebar"
              icon={<MenuOutlined />}
              onClick={() => setShowSidebar((prev) => !prev)}
            />
            <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
            <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} />
          </BottomNavigation>
        </Paper>


         */}
      </div>
    </>
  );
};

export { Layout };
