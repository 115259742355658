// src/lib/DynamicComponents.js

import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";

const DynamicComponents = (props) => {
  const { component } = useParams();
  const location = useLocation();

  let ComponentToRender;

  try {
    if (component) {
      ComponentToRender = lazy(() => import(`../components/${component}`));
    }
  } catch (error) {
    console.error("Component not found:", error);
    return <div>Component not found</div>;
  }

  const searchParams = new URLSearchParams(location.search);
  const dynamicProps = {};

  searchParams.forEach((value, key) => {
    dynamicProps[key] = value;
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {ComponentToRender ? (
        <ComponentToRender {...dynamicProps} {...props}>
          {props.children}
          {dynamicProps.children && (
            <div dangerouslySetInnerHTML={{ __html: dynamicProps.children }} />
          )}
        </ComponentToRender>
      ) : (
        <div>Component not found</div>
      )}
    </Suspense>
  );
};

export default DynamicComponents;
