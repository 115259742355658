// store.js

import { create } from 'zustand';
import { createStateWithStorage } from './lib/StorageHelper';

const useStore = create((set) => ({
  credits: 100,
  setCredits: (newCredits) => set({ credits: newCredits }),

  ...createStateWithStorage('userDetails', null)(set),
  ...createStateWithStorage('jwtToken', null)(set),
  // ...createStateWithStorage('tableInstance', null)(set),
  ...createStateWithStorage('current_template', null)(set),

  clearAuth: () => {
    setLocalStorageItem('userDetails', null);
    setLocalStorageItem('jwtToken', null);
    set({ userDetails: null, jwtToken: null });
  },
}));

export default useStore;
