// src/FetchAPI.js

import { Toaster } from "../components/ui/sonner.jsx";
import { toast } from "sonner";

// const BASE_API = "https://ma.cloata.com";
//const BASE_API = "http://localhost:8000";
const BASE_API =
  window.location.hostname === "localhost"
    ? "http://localhost:9417"
    : // ? "http://localhost:8000"
      "https://ma.cloata.com";

const JWT_ENDPOINT = `${BASE_API}/jwt`;
const MUTATION_ENDPOINT = `${BASE_API}/m`;
const PUBLIC_ENDPOINT = `${BASE_API}/public`;

const buildQueryParams = (params) => {
  if (typeof params === "string") return params;
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};
const safeFetch = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw response;
    }
    return response;
  } catch (error) {
    return error;
  }
};

// const fetchHelper = async (url, headers) => {

//   const response = await safeFetch(url, {
//     headers,
//     mode: "cors",
//     credentials: "same-origin",
//   });

//   if (!response.ok) {
//     if (response) {
//       const result = await response.text();

//       if (result.match("Invalid token")) {
//         console.error("Not logged in, redirecting to cloata.com/clt");
//         window.location.href = `https://cloata.com/clt`;
//       }

//       toast.error(result, { duration: Infinity, closeButton: true });
//       return false;
//     }
//   }
//   return response.json();
// };

const fetchHelper = async (url, headers) => {
  const response = await safeFetch(url, {
    headers,
    mode: "cors",
    credentials: "same-origin",
  });

  if (!response.ok) {
    if (response && response?.text) {
      const result = await response.text();

      if (result.match("Invalid token")) {
        toast.error("Not logged in, redirecting to login", {
          duration: Infinity,
          closeButton: true,
        });
        window.location.href = `https://cloata.com/app`;
        return false;
      }

      toast.error(result, { duration: Infinity, closeButton: true });
      return false;
    } else {
      toast.error("Error", { duration: Infinity, closeButton: true });
      return false;
    }
  }
  const j = response?.json ? response.json() : false;
  if (!j) {
    toast.error("Error", { duration: Infinity, closeButton: true });
    return false;
  }
  return j;
};

const fetchHelperStreaming = async (url, headers) => {
  const response = await safeFetch(url, {
    headers,
    mode: "cors",
    credentials: "same-origin",
  });

  if (!response.ok) {
    if (response && response?.text) {
      const result = await response.text();

      if (result.match("Invalid token")) {
        toast.error("Not logged in, redirecting to login", { duration: Infinity, closeButton: true });
        window.location.href = `https://cloata.com/app`;
        return false;
      }

      toast.error(result, { duration: Infinity, closeButton: true });
      return false;
    } else {
      toast.error("Error", { duration: Infinity, closeButton: true });
      return false;
    }
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let result = "";
  let done = false;
  let parsedResults = [];

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    result += decoder.decode(value, { stream: true });

    let boundary = result.indexOf("\n");

    while (boundary !== -1) {
      const chunk = result.slice(0, boundary).trim();
      result = result.slice(boundary + 1);

      if (chunk) {
        try {
          parsedResults.push(JSON.parse(chunk));
        } catch (error) {
          toast.error("Error parsing JSON", { duration: Infinity, closeButton: true });
          return false;
        }
      }
      boundary = result.indexOf("\n");
    }
  }

  return parsedResults;
};


const postAPI = async ({
  endpoint,
  body,
  description = "loading...",
  duration = Infinity,
}) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const finalUrl = `${JWT_ENDPOINT}${endpoint || ""}`;
  console.log("finalUrl:", finalUrl);

  const toastId = toast.loading("Fetching...", {
    description: description,
    duration: duration,
    closeButton: false,
    action: {
      label: "Close",
      onClick: () => console.log("Close"),
    },
  });

  try {
    const response2 = await fetch(finalUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
      mode: "cors",
    });

    const response = response2;

    if (!response2.ok) {
      let errorMessage = "Network response was not ok";

      try {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          const result = await response.json();
          errorMessage =
            result?.message || result?.detail || JSON.stringify(result);
        } else if (response.text) {
          const result = await response.text();
          errorMessage = result || errorMessage;
        } else {
          errorMessage = "An unknown error occurred";
        }
      } catch (error) {
        console.error("Error parsing response:", error);
      }

      // toast.error(`Error: ${errorMessage}`, {
      //   id: toastId,
      //   description: "Something went wrong.",
      // });

      throw new Error(errorMessage);
    }

    const data = await response.json();

    toast.success(`Success`, {
      //toast.success(`Success: ${JSON.stringify(data.message)}`, {
      id: toastId,
      description: "Operation completed successfully.",
      duration: 2000,
    });

    return data;
  } catch (error) {
    let errorMessage = error?.message || JSON.stringify(error);
    toast.error(`Error: ${errorMessage}`, {
      id: toastId,
      description: "Something went wrong.",
    });
    return false;
  }
};

const mutateAPI = async ({ queryKey }) => {
  const [params] = queryKey;
  const jwtToken = localStorage.getItem("jwtToken");
  const queryString = buildQueryParams(params);
  console.log("Query Params:", queryString);

  const url = `${MUTATION_ENDPOINT}?${queryString}`;
  const finalUrl = url.endsWith("?") ? url.slice(0, -1) : url;
  console.log("finalUrl:", finalUrl);

  return fetchHelper(finalUrl, {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  });
};

const fetchAPI = async ({ queryKey }) => {
  const [params] = queryKey;
  const jwtToken = localStorage.getItem("jwtToken");
  const queryString = buildQueryParams(params);
  console.log("Query Params:", queryString);

  const url = `${JWT_ENDPOINT}?${queryString}`;
  const finalUrl = url.endsWith("?") ? url.slice(0, -1) : url;
  console.log("finalUrl:", finalUrl);

  return fetchHelper(finalUrl, {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  });
};

const fetchPublicAPI = async ({ queryKey }) => {
  const [params] = queryKey;
  const queryString = buildQueryParams(params);
  console.log("Query Params:", queryString);

  const url = `${PUBLIC_ENDPOINT}?${queryString}`;
  const finalUrl = url.endsWith("?") ? url.slice(0, -1) : url;
  console.log("finalUrl:", finalUrl);

  return fetchHelper(finalUrl, {
    "Content-Type": "application/json",
    Accept: "application/json",
  });
};

export { fetchAPI, fetchPublicAPI, mutateAPI, postAPI };
