import { useTheme } from "@mui/material/styles";
import { Box, Grid, Avatar } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Typography } from "@mui/material";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";

import ChartComponent from "./ChartDemo";
import {ChartComponentTooltip} from "./ChartDemo";

export default function DashboardStats() {
  const theme = useTheme();

  const stats = [
    {
      title: "Total Companies",
      value: "29,32,000",
      description: "Information available to sell",
      icon: <BusinessIcon fontSize="large" />,
    },
    {
      title: "New Today",
      value: "542",
      description: "Newly incorporated companies today",
      icon: <EventNoteIcon fontSize="large" />,
    },
    {
      title: "Last 30 Days",
      value: "22,154",
      description: "Newly incorporated companies in the past 30 days",
      icon: <TrendingUpIcon fontSize="large" />,
    },
  ];

  return (
    <>
      <Carousel
        className="max-h-screen mx-auto lg:max-h-[200px] mx-12 mb-4 px-12 bg-yellow select-none"
        opts={{
          align: "start",
          loop: true,
          dragFree: true,
        }}
      >
        <CarouselContent>
{/*           
          <CarouselItem className="pl-1 md:basis-1/1 lg:basis-1/3 bg-red">
            <ChartComponent className="bg-blue"/>
          </CarouselItem>

          <CarouselItem className="pl-1 md:basis-1/1 lg:basis-1/3 bg-red">
            <ChartComponentTooltip />
          </CarouselItem> */}

          {stats.map((stat, index) => (
            <CarouselItem
              key={index}
              className="pl-1 md:basis-1/1 lg:basis-1/3"
            >
              <Card className="hover:shadow-xl transition-shadow duration-300 rounded-lg">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-lg font-medium">
                    {stat.title}
                  </CardTitle>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      width: 56,
                      height: 56,
                    }}
                  >
                    {stat.icon}
                  </Avatar>
                </CardHeader>
                <CardContent>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      {stat.value}
                    </Typography>
                    <CardDescription className="text-sm text-gray-500">
                      {stat.description}
                    </CardDescription>
                  </Box>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </>
  );
}
