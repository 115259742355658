// src/App.js

import React, { useState, useRef, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { ThemeProvider } from "./components/theme-provider";
// import Dashboard from "./components/Dashboard";
import { Layout } from "./components/Layout";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import { Menu } from "./components/Menu";
import { CardViewMRT } from "./components/CardViewMRT";
import { DidYouKnow } from "./components/DidYouKnow";
import { lightTheme, darkTheme } from "./theme";
import { useTheme } from "./components/theme-provider";
// import CompanyCard from "./components/CompanyCard";
// import { CompanyCardLoading } from "./components/CompanyCard";
import DashboardStats from "./components/DashboardStats";
import { Switch } from "./components/ui/switch";
import { Slider } from "./components/ui/slider";

import Joyride from "react-joyride";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Menu as MenuIcon } from "lucide-react";
import FramerDemo from "./components/Framer.jsx";
import NestedGrid from "./components/Grid";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { clarity } from "react-microsoft-clarity";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./components/ui/dropdown-menu";

import useStore from "./store";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./components/ui/collapsible";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  lighten,
  darken,
} from "@mui/material";

import Link from "@mui/material/Link";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./components/ui/accordion";

import DropdownExampleAllowAdditions from "./components/SemanticDropdown";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from "./components/ui/card";
import { Separator } from "./components/ui/separator";
import { Toaster } from "./components/ui/sonner.jsx";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./components/ui/hover-card";

import { Textarea } from "./components/ui/textarea";
import { Skeleton } from "./components/ui/skeleton";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./components/ui/tooltip";

import { Container } from "@mui/material";

import { Calendar } from "./components/ui/calendar";

import DataTable from "./lib/DataTable";
import MaterialDataTable from "./lib/MaterialDataTable";
import { MRT_ActionMenuItem, MRT_SelectCheckbox } from "material-react-table";
import { jwtDecode } from "jwt-decode";

import sampleData_demo from "./SampleData";
import { fetchAPI, fetchPublicAPI, mutateAPI } from "./lib/FetchAPI";
import "./index.css"; // Import your CSS file
import { Edit, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { toast } from "sonner";

function DropdownMenuRadioGroupDemo({ tableInstance }) {
  const [sliderValue, setSliderValue] = React.useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedTableRows, setSelectedTableRows] = useState(0);
  const [selectMode, setSelectMode] = useState("all"); // "all", "some", "none"

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">SELECT LEADS</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>
            Select All / None or move slider
          </DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuRadioGroup
            value={selectMode}
            onValueChange={setSelectMode}
          >
            <DropdownMenuRadioItem
              value="all"
              onClick={() => {
                setSelectMode("all");
                if (!tableInstance) return;
                tableInstance.toggleAllPageRowsSelected(true);
              }}
            >
              All
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem
              value="some"
              onClick={() => {
                setSelectMode("some");
              }}
            >
              Drag
              <Slider
                value={[sliderValue]}
                onValueChange={(value) => {
                  setIsAllSelected(false);
                  setSliderValue(value[0]);
                  if (!tableInstance) return;
                  tableInstance.toggleAllRowsSelected(false); // Deselect all rows first

                  clearTimeout(window.sliderTimeout);
                  window.sliderTimeout = setTimeout(() => {
                    tableInstance.toggleAllRowsSelected(false); // Deselect all rows first
                    const rowsToSelect = tableInstance
                      .getRowModel()
                      .rows.slice(0, value[0]);

                    setSelectedTableRows(
                      tableInstance.getSelectedRowModel().rows.length
                    );

                    rowsToSelect.forEach((row) => {
                      //tableInstance.getRowModel().rows.find((r) => r.id === row.id)?.getToggleSelectedHandler(true)();

                      tableInstance
                        .getRowModel()
                        .rows.find((r) => r.id === row.id)
                        ?.toggleSelected();
                    });
                  }, 500); // Half-second delay
                }}
                max={tableInstance.getRowModel().rows.length}
                className="ml-4"
              >
                Select Some
              </Slider>
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem
              value="none"
              onClick={() => {
                setSelectMode("none");

                if (!tableInstance) return;
                tableInstance.resetRowSelection();
              }}
            >
              None
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      {sliderValue && <span className="">{sliderValue}</span>}
    </>
  );
}

const App = ({ initjwtToken, queryClient }) => {
  const { theme } = useTheme();

  return (
    <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <CssBaseline />

      <MainContent
        theme={theme}
        initjwtToken={initjwtToken}
        queryClient={queryClient}
      ></MainContent>

      {/* 
      <Layout
        sidebar={
          <Sidebar>
            <Menu
              templateOptions={templateOptions}
              selectedTemplateValue={selectedTemplateValue}
              handleTemplateChange={handleTemplateChange}
            >
            </Menu>
          </Sidebar>
        }
        header={<Header />}
        main={
          <MainContent
            theme={theme}
            initjwtToken={initjwtToken}
            queryClient={queryClient}
            templateOptions={templateOptions}
            selectedTemplateValue={selectedTemplateValue}
            handleTemplateChange={handleTemplateChange}
          />
        }
      /> */}

      <Toaster
        expand={true}
        position={"top-center"}
        richColors
        theme={"system"}
        invert={false}
        toastOptions={{
          className: "my-toast",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const MainContent = ({ theme, initjwtToken, queryClient }) => {
  const steps = [
    {
      target: '[data-key="mca-live-section8"]',
      content: "This is the first target element!",
    },
    {
      target: '[data-key="my-contacts"]',
      content: "This is the second target element!",
    },
  ];
  // const [tableInstance, setTableInstance] = useStore();
  const [tableInstance, setTableInstance] = useState(null);

  const [selectMode, setSelectMode] = useState("all"); // "all", "some", "none"
  const [sliderValue, setSliderValue] = useState(100);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const { userDetails, setUserDetails, clearAuth } = useStore();
  const { current_template, setCurrent_template } = useStore();

  const [jwtToken, setjwtToken] = useState(initjwtToken);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const tokenFromUrl = urlParams.get("jwt_token");
    const storedjwtToken = localStorage.getItem("jwtToken");

    if (tokenFromUrl) {
      const decodedToken = JSON.parse(atob(tokenFromUrl.split(".")[1]));
      setjwtToken(tokenFromUrl);
      localStorage.setItem("jwtToken", tokenFromUrl);
      localStorage.setItem("userDetails", JSON.stringify(decodedToken));
      handleSearch();
    } else if (storedjwtToken && initialParams) {
      const decodedToken = JSON.parse(atob(storedjwtToken.split(".")[1]));
      localStorage.setItem("userDetails", JSON.stringify(decodedToken));
      handleSearch();
    }
  }, []);

  // const initialEndpoint = urlParams.get("endpoint") || "/data/mca/companies_v2";
  const initialParams = urlParams.get("params") || "";

  const initialMessage =
    urlParams.get("message") ||
    `Hi!

This is a test message.

Thanks!`;

  const token = localStorage.getItem("jwtToken");
  let api_func = fetchPublicAPI;

  if (token) {
    api_func = fetchAPI;
  }

  const fetchTemplateData = async () => {
    const templateData = await queryClient.fetchQuery({
      queryKey: ["template=self-templates"],
      queryFn: api_func,
      enabled: !!queryParams, // Only run query if endpoint is not empty
      staleTime: 1800000, // 30 minutes in milliseconds
      refetchInterval: 1800000, // 30 minutes in milliseconds
    });

    if (!templateData) {
      return false;
    }

    console.log("Template Data:", templateData);

    const options = templateData.reduce((acc, item) => {
      try {
        // Extract variables from item.url
        const variableMatches = item.url.match(/@\w+@/g) || [];
        const uniqueVariables = [
          ...new Set(variableMatches.map((v) => v.slice(1, -1))),
        ];

        // Store variables globally using item._id as the identifier
        globalThis[String(item._id)] = uniqueVariables;

        // Prepare the label
        const label =
          item?.label ||
          item._id
            .toLowerCase()
            .replace(/[_-]/g, " ")
            .trim()
            .replace(/\b\w/g, (char) => char.toUpperCase());

        item.group = item?.group
          ? item.group
              .toLowerCase()
              .replace(/[_-]/g, " ")
              .trim()
              .replace(/\b\w/g, (char) => char.toUpperCase())
          : "";

        // Determine group
        const group = item?.group;

        if (group) {
          // Initialize the group if it doesn't exist
          if (!acc[group]) {
            acc[group] = [];
          }

          // Add the item to the group
          acc[group].push({ value: item._id, label });
        }
      } catch (error) {
        console.error("Error processing item:", item, error);
      }

      return acc;
    }, {});

    setTemplateOptions(options);
    setLoading(false);
  };

  const [message, setMessage] = useState(initialMessage);
  const [templateOptions, setTemplateOptions] = useState([]);

  const [queryParams, setQueryParams] = useState("");
  const [paramsInput, setParamsInput] = useState(initialParams);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState("");

  const handleParamsChange = (e) => {
    console.log("handleParamsChange");
    setParamsInput(e.target.value);
  };

  const handleTemplateChange = (event) => {
    console.log("handleTemplateChange");
    const selectedValue = event.target.value;
    window.history.pushState(null, "", `/discover/${selectedValue}`);
    window.location = `/discover/${selectedValue}`;
    return false;
    setSelectedTemplateValue(selectedValue);

    setQueryParams(`template=${selectedValue}`);
    setParamsInput(selectedValue);
    setSelectedTemplate(selectedValue);

    // Log the variables for the selected template
    const variables = globalThis[selectedValue] || [];
    console.log(variables);

    const inputs = variables
      .filter((variable) => !variable.startsWith("_"))
      .map((variable) => (
        <TextField
          key={variable}
          id={variable}
          name={variable}
          placeholder={variable}
          style={{ width: "100%", padding: 10, marginBottom: 10 }}
        />
      ));

    setVariableInputs(inputs);
  };

  const handleCreateNewRow = async ({ values, table }) => {
    const queryKey = [
      {
        method: "create",
        // config_id: "66b0a5fa20950218f08f63a4",
        config_name: paramsInput,
        docs: JSON.stringify(values),
      },
    ];

    console.log(queryKey);

    await queryClient.fetchQuery({
      queryKey,
      queryFn: mutateAPI,
      enabled: !!values,
    });

    // queryClient.invalidateQueries([updatedParamsInput]);

    handleSearch();
    table.setCreatingRow(null); //exit creating mode
  };

  const handleEditRow = async ({ values, table }) => {
    console.log(values);

    const queryKey = [
      {
        method: "update",
        // config_id: "66b0a5fa20950218f08f63a4",
        config_name: paramsInput,
        query: `{"_id":"${table
          .getState()
          .editingRow.original._id.toString()}"}`,

        docs: JSON.stringify(values),
      },
    ];

    console.log(queryKey);

    try {
      const response = await queryClient.fetchQuery({
        queryKey,
        queryFn: mutateAPI,
        enabled: !!values, // Only run query if values are not empty
      });
      console.log("Mutate API Response:", response);
      toast.success(JSON.stringify(response));
    } catch (error) {
      console.error("Error occurred during mutation:", error);
      toast.error(JSON.stringify(error), {
        duration: Infinity,
        closeButton: false,
        action: {
          label: "Close",
        },
      });
      // Assuming `setError` is a function to display error in MRT edit modal
      // setError("mutationError", { message: error.message });
    }

    handleSearch();
    table.setEditingRow(null); //exit editing mode
    // queryClient.invalidateQueries([updatedParamsInput]);
  };

  useEffect(() => {
    const urlPath = window.location.pathname;
    const match = urlPath.match(/^\/discover\/(.+)/);
    if (match) {
      const template2 = match[1];
      console.log(template2);
      // setQueryParams(`template=${template2}`);
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      setQueryParams(`${searchParams.toString()}&template=${template2}`);

      setCurrent_template(template2);

      if (template2) {
        setSelectedTemplateValue(template2);
      }

      //document.querySelector(`[data-key="${template}"]`)?.setAttribute("data-selected", true);
    }

    fetchTemplateData();
  }, []);

  // useEffect(() => {

  //   fetchTemplateData();

  // }, []);

  const handleSearch = () => {
    console.log("handleSearch");
    // if (username) localStorage.setItem("username", username);
    // if (password) localStorage.setItem("password", password);
    if (jwtToken) {
      localStorage.setItem("jwtToken", jwtToken);
      fetchTemplateData();
    }

    if (paramsInput) {
      localStorage.setItem("template", paramsInput);
    }

    let updatedParamsInput = `template=${paramsInput}`;
    const variables = globalThis[paramsInput] || [];

    variables.forEach((variable) => {
      const element = document.getElementById(variable);
      if (element) {
        const value = element.value;
        updatedParamsInput += `&${variable}=${encodeURIComponent(value)}`;
      }
    });

    setQueryParams(updatedParamsInput);
    queryClient.invalidateQueries([updatedParamsInput]);
  };

  const {
    data: sampleData = {},
    isLoading,
    error,
  } = useQuery({
    queryKey: [queryParams],
    queryFn: fetchAPI,
    enabled: !!queryParams,
    staleTime: 1800000,
    refetchInterval: 1800000,
  });

  const processedData = useMemo(() => {
    if (!sampleData || !Array.isArray(sampleData)) return [];
    return sampleData.map((row) => ({
      ...row,
      //computedValue: row.value * 2, // Example transformation
    }));
  }, [sampleData]);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;
  const openDeleteConfirmModal = async (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      //deleteRow(row);
      // deleteRow(row.original._id);

      const queryKey = [
        {
          method: "delete",
          // config_id: "66b0a5fa20950218f08f63a4",
          config_name: paramsInput,
          query: `{"_id":"${row.original._id}"}`,

          // docs: JSON.stringify(values),
        },
      ];

      console.log(queryKey);

      try {
        const response = await queryClient.fetchQuery({
          queryKey,
          queryFn: mutateAPI,
          enabled: !!row.original, // Only run query if values are not empty
        });
        console.log("Mutate API Response:", response);
        toast.success(JSON.stringify(response));

        handleSearch();
      } catch (error) {
        console.error("Error occurred during mutation:", error);
        // Assuming `setError` is a function to display error in MRT edit modal
        //setError("mutationError", { message: error.message });
        toast.error(JSON.stringify(error.message), {
          duration: Infinity,
          closeButton: true,
        });
      }
    }
  };

  const deleteRow = async ({ row_id }) => {
    console.log(row_id);

    // const queryKey = [
    //   {
    //     method: "delete",
    //     // config_id: "66b0a5fa20950218f08f63a4",
    //     config_name: paramsInput,
    //     query: `{"_id":"${row_id}"}`,

    //     docs: JSON.stringify(values),
    //   },
    // ];

    // console.log(queryKey);

    // try {
    //   const response = await queryClient.fetchQuery({
    //     queryKey,
    //     queryFn: mutateAPI,
    //     enabled: !!values, // Only run query if values are not empty
    //   });
    //   console.log("Mutate API Response:", response);
    // } catch (error) {
    //   console.error("Error occurred during mutation:", error);
    //   // Assuming `setError` is a function to display error in MRT edit modal
    //   setError("mutationError", { message: error.message });
    // }

    // handleSearch()
    // table.setEditingRow(null); //exit editing mode
    // // queryClient.invalidateQueries([updatedParamsInput]);
  };

  const parseLines = (value) => value.replace(/(\\n)/g, "\n");

  const renderDetailPanel = ({ row }) => (
    <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>
      {row.original.mobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 0,
          }}
        >
          <Typography variant="h4">{row.original.mobile}</Typography>
          <Box sx={{ display: "flex", gap: "16px", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              href={`tel://${row.original.mobile}`}
              target="_blank"
            >
              <PhoneIcon /> Call
            </Button>

            <Button
              variant="contained"
              color="success"
              href={`whatsapp://send?phone=91${row.original.mobile}&text=${message}`}
              target="_blank"
            >
              <WhatsAppIcon />
              &nbsp;WhatsApp
            </Button>

            <Button
              variant="contained"
              color="success"
              href={`https://wa.me/91${row.original.mobile}?text=${message}`}
              target="_blank"
            >
              <WhatsAppIcon />
              &nbsp;WhatsApp
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <Typography variant="h6">Call Status</Typography>
          <Select
            fullWidth
            variant="outlined"
            defaultValue={row.original.callStatus}
            onChange={(e) =>
              handleCallStatusChange(row.id, e.target.value, row)
            }
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="successful">Successful</MenuItem>
            <MenuItem value="unsuccessful">Unsuccessful</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant="h6">Call Notes</Typography>
          <TextField fullWidth multiline rows={4} variant="outlined" />
        </Box>
      </Box>

      {/* 
      <pre>{JSON.stringify(row.original, null, 2)}</pre>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigator.clipboard.writeText(JSON.stringify(row.original, null, 2))}
      >
        Copy JSON
      </Button> */}
    </Box>
  );

  useEffect(() => {
    clarity.init("np7h5u9v3j");
  }, []);

  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [variableInputs, setVariableInputs] = useState([]);
  const [run, setRun] = useState(true);

  const [loading, setLoading] = useState(true);

  const handleRestart = (event) => {
    event.preventDefault();
    setRun(false); // Stop the tour
    setTimeout(() => setRun(true), 100); // Restart the tour after a brief delay
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      setRun(false); // Stop the tour after it completes
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [selectedTableRows, setSelectedTableRows] = useState(0);

  // useEffect(() => {
  //   //clarity.init("np7h5u9v3j");
  // }, [selectedTableRows]);

  return (
    <Layout
      theme={theme}
      setShowSidebar={setShowSidebar}
      showSidebar={showSidebar}
      sidebar={
        <>
          <Sidebar theme={theme} setIsOpen={setIsOpen} isOpen={isOpen}>
            <Menu
              templateOptions={templateOptions}
              selectedTemplateValue={selectedTemplateValue}
              handleTemplateChange={handleTemplateChange}
              loading={loading}
              // href={`/discover/${selectedTemplateValue}`}
            ></Menu>
          </Sidebar>
        </>
      }
      header={
        <Header
          theme={theme}
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        >
          {/* <motion.button
            whileHover={{ scale: 1.3 }}
            whileTap={{ scale: 0.9 }}
            style={{ backgroundColor: "transparent" }}
          >
            <Button
              variant="outline"
              size="icon"
              className="ml-2 h-8 w-8 lg:hidden mt-8"
              zIndex={"9999999"}
              onClick={() => setIsOpen(!isOpen)}
            >
              <MenuIcon className="h-4 w-4" />
              <span className="sr-only">Toggle Sidebar</span>
            </Button>
          </motion.button>

           */}
        </Header>
      }
      main={
        <>
          <TooltipProvider>
            <DashboardStats />
            {/* <CardViewMRT/> */}
            {/*         
          <Box className="w-full">
            <Accordion type="multiple" collapsible>
              <AccordionItem value="item-1" open="true">
                <AccordionTrigger>Settings</AccordionTrigger>
                <AccordionContent>
                  <Textarea
                    className={"m-4"}
                    sx={""}
                    // fullWidth
                    multiline
                    rows={5}
                    value={parseLines(message)}
                    onChange={(e) => setMessage(parseLines(e.target.value))}
                    cols={20}
                    placeholder="Enter your message"
                    // variant="outlined"
                    inputProps={{ style: { whiteSpace: "pre-wrap" } }}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            {variableInputs}

            {variableInputs && variableInputs.length > 0 && (
              <>
                <Separator />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{ padding: 10 }}
                >
                  Search
                </Button>
              </>
            )}
          </Box> */}
            {/*           

            <Box className="flex w-full p-6">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="contained"
                    onClick={handleRestart}
                    className="mt-4 p-2 bg-indigo-500 text-white rounded"
                    startIcon={<HelpOutlineIcon />}
                  >
                    {" "}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Restart the tour</TooltipContent>
              </Tooltip>
            </Box> */}

            <Box>
              <Joyride
                steps={steps}
                run={run}
                continuous
                showSkipButton
                callback={handleJoyrideCallback}
                disableScrolling={true}
                styles={{
                  options: {
                    zIndex: 10000,
                    // primaryColor: "#6366f1",
                  },
                }}
              />
              {/* 
              <Button
                onClick={() => {
                  setSelectMode("all");
                  if (!tableInstance) return;
                  tableInstance.toggleAllPageRowsSelected(true);
                }}
                className="ml-4"
              >
                Select All
              </Button>

              <Switch
                checked={!isAllSelected}
                onCheckedChange={() => {
                  setIsAllSelected(false);
                  if (!tableInstance) return;
                  tableInstance.toggleAllRowsSelected(false); // Deselect all rows
                }}
                className="ml-4"
              >
                Select None
              </Switch> */}

              {tableInstance && (
                <>
                  <DropdownMenuRadioGroupDemo tableInstance={tableInstance} />
                  {/* 


                  <div className="text-sm text-gray-500 mb-2">
                    Slider : {sliderValue}
                    
                  </div>



 <Button
                    onClick={() => {
                      if (!tableInstance) return;
                      tableInstance.toggleAllPageRowsSelected(true);
                    }}
                    className="ml-4"
                  >
                    Select All
                  </Button>

                  <Button
                    onClick={() => {
                      if (!tableInstance) return;
                      tableInstance.resetRowSelection();
                    }}
                    className="ml-4"
                  >
                    Unselect All
                  </Button>

                  
                  <Slider
                    value={[sliderValue]}
                    onValueChange={(value) => {
                      setIsAllSelected(false);
                      setSliderValue(value[0]);
                      if (!tableInstance) return;
                      tableInstance.toggleAllRowsSelected(false); // Deselect all rows first

                      clearTimeout(window.sliderTimeout);
                      window.sliderTimeout = setTimeout(() => {
                        tableInstance.toggleAllRowsSelected(false); // Deselect all rows first
                        const rowsToSelect = tableInstance
                          .getRowModel()
                          .rows.slice(0, value[0]);

                        setSelectedTableRows(
                          tableInstance.getSelectedRowModel().rows.length
                        );

                        rowsToSelect.forEach((row) => {
                          //tableInstance.getRowModel().rows.find((r) => r.id === row.id)?.getToggleSelectedHandler(true)();

                          tableInstance
                            .getRowModel()
                            .rows.find((r) => r.id === row.id)
                            ?.toggleSelected();
                        });
                      }, 500); // Half-second delay
                    }}
                    max={tableInstance.getRowModel().rows.length}
                    className="ml-4"
                  >
                    Select Some
                  </Slider> */}
                </>
              )}

              {/* 
              <Button
                onClick={() => {
                  if (!tableInstance) return;
                  const sortState = [{ id: "paidUpCapital", desc: true }];
                  tableInstance.setSorting(sortState);
                }}
                className="ml-4"
              >
                Sort by Paid Up Capital Desc
              </Button>

              <Button onClick={() => tableInstance.resetSorting(true)}>
                Clear All Sorting
              </Button> */}

              {isLoading && <CompanyCardLoading />}

              {/* {isLoading && <CompanyCardLoading />} */}

              {error && <p>Error: {error.message}</p>}
              {error &&
                toast.error(error, { duration: Infinity, closeButton: true })}

              {processedData &&
                Array.isArray(processedData) &&
                processedData.length > 0 && (
                  <MaterialDataTable
                    handleTemplateChange={handleTemplateChange}
                    data={processedData}
                    tableInstance={tableInstance}
                    // enableEditing
                    // enableRowActions

                    enableInfiniteScroll={true}
                    muiTableBodyProps={({ table }) => {
                      if (!tableInstance) {
                        setTableInstance(table);
                      }

                      return {
                        children: (
                          <TableComponent
                            current_template={current_template}
                            table={table}
                            tableInstance={tableInstance}
                          />

                          // <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                          //   {table.getRowModel().rows.map((row) => {

                          //     return (
                          //       <DynamicCardComponent
                          //         key={row.id}
                          //         row={row.original}
                          //         template={current_template}

                          //         isSelected={
                          //           !!table.getState().rowSelection[row.id]
                          //         }
                          //         onSelect={() => {
                          //           const isSelected =
                          //             table.getState().rowSelection[row.id];
                          //           table.setRowSelection({
                          //             ...table.getState().rowSelection,
                          //             [row.id]: !isSelected,
                          //           });
                          //         }}
                          //       />
                          //     );
                          //   })}
                          // </Box>
                        ),

                        // onRowClick: (row) => {
                        //   const isSelected =
                        //     table.getState().rowSelection[row.id];
                        //   table.setRowSelection({
                        //     ...table.getState().rowSelection,
                        //     [row.id]: !isSelected,
                        //   });
                        // },
                      };
                    }}
                    muiToolbarAlertBannerProps={{
                      color: "success",
                    }}
                    positionToolbarAlertBanner="head-overlay"
                    renderToolbarAlertBannerContent={({
                      selectedAlert,
                      table,
                    }) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            gap: "6px",
                            p: "4px 12px",
                            width: "100%",
                          }}
                        >
                          <MRT_SelectCheckbox table={table} /> {selectedAlert}
                          {" Select 100"}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "6px",
                          }}
                        >
                          <Button
                            color="success"
                            // startIcon={<Send />}
                            variant="contained"
                          >
                            Email
                          </Button>
                          <Button
                            color="error"
                            // startIcon={<Remove />}
                            variant="contained"
                          >
                            Remove
                          </Button>
                        </Box>
                      </Box>
                    )}
                    enableTableHead={false}
                    enablePagination={false}
                    density="compact"
                    message={parseLines(message)}
                    onCreatingRowSave={handleCreateNewRow}
                    onEditingRowSave={handleEditRow}
                    positionActionsColumn={"first"}
                    // displayColumnDefOptions={{
                    //   "mrt-row-actions": {
                    //     header: "CRM Actions",
                    //     size: 150,
                    //   },
                    // }}

                    displayColumnDefOptions2={({ row }) =>
                      (row.original?.mobile ?? "") && {
                        "mrt-row-actions": {
                          header: "CRM Actions",
                          size: 150,
                        },
                      }
                    }
                    renderRowActions2={({ row, table }) => {
                      const mobile = row.original?.mobile ?? "";
                      if (!mobile) return "No mobile";

                      return (
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            padding: "2px",
                            flexWrap: "wrap",
                            gap: "2px",
                            width: "100%",
                            minWidth: "200px",
                            maxWidth: "500px",
                            backgroundColor: darken(
                              theme.palette.background.default,
                              2
                            ),
                          })}
                        >
                          {/*                         
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="default"
                  onClick={() => openDeleteConfirmModal(row)}
                  className="text-gray-500"
                >
                  <DeleteIcon />
                </IconButton> */}

                          {mobile && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<WhatsAppIcon />}
                                onClick={() =>
                                  window.open(
                                    `whatsapp://send?phone=91${mobile}&text=${encodeURIComponent(
                                      message
                                    )}`
                                  )
                                }
                              >
                                {""}
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => window.open(`tel://${mobile}`)}
                              >
                                {/* <PhoneIcon />  */}
                                {" Call"}
                              </Button>
                            </>
                          )}
                        </Box>
                      );
                    }}
                  />
                )}

              {/* <NestedGrid /> */}
            </Box>
          </TooltipProvider>
        </>
      }
    />
  );
};

export default function RootApp({ queryClient }) {
  const rootElement = document.getElementById("root");
  const jwtToken = rootElement ? rootElement.getAttribute("data-token") : "";

  useEffect(() => {
    if (jwtToken) {
      console.log(jwtToken);

      const decodedToken = jwtDecode(jwtToken);
      console.log("Decoded JWT Token Payload:", decodedToken);
    }
  }, [jwtToken, queryClient]);

  // if (jwtToken) {
  //   console.log(jwtToken);

  //   const decodedToken = jwtDecode(jwtToken);
  //   console.log("Decoded JWT Token Payload:", decodedToken);

  //   const {
  //     data: templateData = {},
  //     isLoading: isTemplateLoading,
  //     error: templateError,
  //   } = useQuery({
  //     queryKey: ["template=self-templates"],
  //     queryFn: fetchAPI,
  //     enabled: !!queryParams, // Only run query if endpoint is not empty
  //     onSuccess: (data) => {
  //       console.log("Template Data:", data);
  //     },
  //   });
  //  }

  return (
    <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
      {/* <MultiSelectApp /> */}
      <App initjwtToken={jwtToken} queryClient={queryClient} />
    </ThemeProvider>
  );
}

import { Suspense, lazy } from "react";

const loadComponent = async (template) => {
  try {
    const componentName =
      template
        .replace(/[^a-z0-9]/gi, "") // Normalize the template name
        .charAt(0)
        .toUpperCase() + template.slice(1);

    const component = await import(`./components/cards/${componentName}`);
    return component.default || component;
  } catch (error) {
    console.error(
      `Component for ${template} not found, loading default component.`,
      error
    );
    const fallbackComponent = await import("./components/cards/GenericCard");
    return fallbackComponent.default || fallbackComponent;
  }
};

const CompanyCardLoading = () => {
  return (
    <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {Array.from({ length: 15 }).map((_, index) => (
        <Card key={index} className="rounded-lg shadow-lg">
          <CardHeader className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-full" />
            <div className="flex-1 space-y-2">
              <Skeleton className="h-6 w-1/2" />
              <Skeleton className="h-4 w-1/4" />
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            <Skeleton className="h-6 w-3/4" />
            <Skeleton className="h-6 w-1/2" />
            <Skeleton className="h-6 w-2/3" />
            <Skeleton className="h-6 w-1/3" />
          </CardContent>
          <CardFooter className="flex justify-between">
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-4 w-1/4" />
          </CardFooter>
        </Card>
      ))}
    </Box>
  );
};

const TableComponent = ({ current_template, table }) => {
  const [CardComponent, setCardComponent] = useState(() =>
    lazy(() => import("./components/cards/GenericCard"))
  );

  useEffect(() => {
    const fetchComponent = async () => {
      const Component = await loadComponent(current_template);
      setCardComponent(() =>
        lazy(() => Promise.resolve({ default: Component }))
      );
    };
    fetchComponent();
  }, [current_template]);

  if (!table) {
    return <CompanyCardLoading />;
  }

  return (
    <Suspense
      fallback={
        <div>
          Rendering...
          <CompanyCardLoading />
        </div>
      }
    >
      <Box
        className="flex flex-col justify-start"
        style={{ padding: "1rem" }} // Optional: Adjust padding as needed
      >
        {/* <CompanyCardLoading/> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 flex-grow">
          {table.getRowModel().rows.map((row) => (
            <CardComponent
              key={row.id}
              row={row.original}
              isSelected={!!table.getState().rowSelection[row.id]}
              onSelect={() => {
                const isSelected = table.getState().rowSelection[row.id];
                table.setRowSelection({
                  ...table.getState().rowSelection,
                  [row.id]: !isSelected,
                });
              }}
            />
          ))}
        </div>
      </Box>
    </Suspense>
  );
};
