import * as React from "react";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "./ui/dialog";
import { Typography } from "@mui/material";
import { Slider } from "./ui/slider.jsx";
import { Button } from "./ui/button";
import { useTheme } from "@mui/material/styles";

export default function BuyCreditsPopup() {
  const [credits, setCredits] = React.useState(1000);
  const theme = useTheme();

  const handleSliderChange = (event) => {
    setCredits(event[0]);
  };

  const handleBuyCredits = () => {
    try {
      console.log(`Purchasing ${credits} credits`);
      // Add logic to process the credit purchase
    } catch (error) {
      console.error("Error purchasing credits:", error);
    }
  };

  return (
    <>
      <Dialog>
        <DialogTrigger title="Add more credits">Buy Credits</DialogTrigger>
        <DialogContent
          className="p-6 max-w-lg mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg"
          style={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
        >
          <DialogTitle className="text-2xl font-bold mb-4">
            Purchase Credits
          </DialogTitle>
          <DialogDescription className="mb-4 text-lg">
            Select the amount of credits you want to purchase. 1 credit = ₹ 1
          </DialogDescription>
          <Slider
            value={[credits]}
            defaultValue={[credits]}
            onValueChange={handleSliderChange}
            min={100}
            max={50000}
            step={100}
            valueLabelDisplay="auto"
            style={{
              color: theme.palette.primary.main,
            }}
          />
          <Typography variant="h6" className="mt-4">
            Selected Credits: ₹ {credits}
          </Typography>
          <Button onClick={handleBuyCredits}>Purchase</Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
